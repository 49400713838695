<template>
	<template-base class="containerCard">
		<div>
			<div class="container-head">
				<div v-if="groupsUsers.length" class="container-pages">
					<b-pagination
						id="page"
						v-model="currentPage"
						:total-rows="pagination.count"
						:per-page="pagination.limit.value"
					></b-pagination>
				</div>
				<div
					v-if="possuiPermissao('GER_I_GRUPO_USUARIO')"
					class="container-add-new"
					v-bind:class="!groupsUsers.length ? 'container-add-new-100' : ''"
				>
					<b-button class="add-button" @click="mostrarEdicao()">
						Adicionar
					</b-button>
				</div>
			</div>
			<div v-if="groupsUsers.length" class="head-row">
				<span class="text-head-row ml-1">
					Resultados {{ pagination.offset + 1 }}-{{ pagination.offset + groupsUsers.length }} de {{ pagination.count }}
				</span>
				<b-form
					class="text-head-row">
					<label class="mr-2">
						Itens por página:
					</label>
					<b-form-select
						class="mr-1 mb-2"
						v-model="pagination.limit.value"
						name="itens-por-pagina"
						:options="optionsItensPorPag"
						@input="changeItensByPage()"
						:disabled="loading"
					/>
				</b-form>
			</div>
			<b-modal
				ref="modalEdicao"
				:title="titleModalEdicao"
				size="lg"
				dialog-class="modal-dialog-centered"
			>
				<div class="modal-edicao d-flex mb-3 modal-edicao-label">
					<b-col cols="12" class="d-block text-funcionalidade">
						<span class="label-funcionalidade">Nome do Grupo de Usuários</span>
						<b-form-input
							name="nome_grupo"
							class="modal-edicao-input"
							type="text"
							v-model="grupoUsuarioSelecionado.nome"
							autocomplete="off"
							placeholder="Digite o nome do grupo de usuários"
							v-validate="{ required: true }"
							:state="validateState('nome_grupo')"
						/>
					</b-col>
				</div>
				<div class="modal-edicao d-flex modal-edicao-label">
					<b-col cols="12" class="d-block mb-3 text-funcionalidade">
						<span class="label-funcionalidade">Filtrar Funcionalidades</span>
						<b-form-input
							name="funcionalidade"
							class="modal-edicao-input"
							type="text"
							placeholder="Digite aqui para filtrar as funcionalidades"
							@input="(v) => filtraFuncionalidades(v)"
						/>
					</b-col>
				</div>
				<div class="table-responsive table-funcionalidades">
					<table class="table table-hover condensed" data-cy="Tabela" :data-asc="true">
						<thead class="table-th-funcionalidade">
							<tr>
								<th>Habilitada</th>
								<th>Funcionalidades</th>
							</tr>
						</thead>
						<tbody class="table-td-funcionalidade">
							<tr v-for="(item, idx) in grupoUsuarioSelecionado.funcionalidades" :key="idx">
								<td v-if="item.visible">
									<ToggleSwitch
										:name="`funcionalidade-${item.id}`"
										:ref="`funcionalidade-${item.id}`"
										:value="item.habilitada"
										@toggle="_ => changeFuncionalidade(idx)"
									/>
								</td>
								<td v-if="item.visible">
									{{ item.nome }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<template #modal-footer>
					<div class="w-100 d-flex justify-content-between">
						<div class="btn-group">
							<b-button class="btn-modal btn-cancel" @click="fecharModal('modalEdicao')">Cancelar</b-button>
							<b-button class="btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
						</div>
						<b-button
							class="btn-modal btn-salvar"
							@click="salvarEdicao"
							:disabled="invalidForm"
						>
							Salvar
						</b-button>
					</div>
				</template>
			</b-modal>
		</div>
		<div v-if="groupsUsers.length" class="table-responsive">
			<DataTable
				class="data-table"
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:nosearch="true"
				:nofilters="true"
				name="gruposUsuarios"
				:hasPagination="true"
				:noedit="true"
				:acoes="true"
				:permissaoEdit="possuiPermissao('GER_U_GRUPO_USUARIO')"
				:permissaoDelete="possuiPermissao('GER_D_GRUPO_USUARIO')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
			/>
		</div>
		<div v-else class="alert alert-warning" role="alert">
			{{ errMsg }}
		</div>
	</template-base>
</template>

<script>
	import ToggleSwitch from "@/components/ToggleSwitch";

	import DataTable from "@/components/DataTable";
	import TemplateBase from "@/templates/Base";

	import { possuiPermissao } from "../../helpers/permissions";
	import { FuncionalidadeService } from "../../services/funcionalidades";
	import { GroupsUsersService } from "../../services/groupsUsers";

	export default {
		components: {
			TemplateBase,
			ToggleSwitch,
			DataTable
		},

		inject: ["parentValidator"],

		data () {
			return {
				groupsUsers: [],
				linhas: [],
				titleModalEdicao: "",
				loading: true,
				funcionalidades: [],
				grupoUsuarioSelecionado: {
					id: "",
					nome: "",
					funcionalidades: []
				},

				invalidForm: true,
				groupsUsersService: new GroupsUsersService(),
				funcionalidadeService: new FuncionalidadeService(),

				errMsg: "Nenhum grupo de usuários cadastrado!",
				colunas: [
					"Grupo de Usuários"
				],
				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Items"
					},
					{
						value: 25,
						text: "25 Items"
					},
					{
						value: 50,
						text: "50 Items"
					},
					{
						value: 75,
						text: "75 Items"
					},
					{
						value: 100,
						text: "100 Items"
					}
				],

				possuiPermissao
			};
		},

		watch: {
			currentPage () {
				this.changePage(this.currentPage);
			}
		},

		created () {
			this.$validator = this.parentValidator;
		},

		async mounted () {
			this.findGroupsUsers();
			this.findFuncionalidades();
		},

		methods: {
			changePage () {
				this.pagination.page = this.currentPage;
				this.findGroupsUsers();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findGroupsUsers();
			},

			async findGroupsUsers () {
				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;

				const result = await this.groupsUsersService.listGroupsUsers({offset, limit}) || {};
				this.groupsUsers = result.rows || [];
				this.linhas = result.rows.reduce((acc, gu) => [
					...acc,
					{
						id: gu.id,
						cols: [gu.nome]
					}
				], []);

				this.pagination.count = result.count || 0;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
			},

			async findFuncionalidades () {
				this.funcionalidades = await this.funcionalidadeService.listFuncionalidades() || [];
				this.funcionalidades = this.funcionalidades.map((f) => ({ ...f, habilitada: false, visible: true }));
			},

			mostrarEdicao (item) {
				let itemEdit;
				this.invalidForm = true;
				this.titleModalEdicao = "Cadastrar Novo Grupo de Usuários";

				if (item && item.id) {
					itemEdit = this.groupsUsers.find(gu => gu.id === item.id);
					this.titleModalEdicao = "Editar Grupo de Usuários";
				}

				this.groupsUsers.forEach(gu => {
					gu.funcionalidades.forEach(f => f.habilitada = true);
				});

				this.grupoUsuarioSelecionado.id = itemEdit?.id || "";
				this.grupoUsuarioSelecionado.nome = itemEdit?.nome || "";

				this.grupoUsuarioSelecionado.funcionalidades = this.funcionalidades.map(f => {
					f.habilitada = itemEdit?.funcionalidades ? itemEdit.funcionalidades.some(it => it.id === f.id) : false;
					f.visible = true;
					return f;
				});
				this.$refs.modalEdicao.show();
			},

			async confirmaApagar (item_) {
				const item = this.groupsUsers.find(gu => gu.id === item_.id);
				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja remover o grupo de usuário?",
					type: "warning",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item.id);
			},

			async salvarEdicao () {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				const payload = {
					nome: this.grupoUsuarioSelecionado.nome,
					funcionalidades: this.grupoUsuarioSelecionado.funcionalidades
				};

				// ID será enviado apenas se necessário
				if (Number.isInteger(this.grupoUsuarioSelecionado.id)) {
					payload.id = this.grupoUsuarioSelecionado.id;
				}

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						await this.groupsUsersService.upsertGroupUser(payload);

						this.fecharModal("modalEdicao");
						this.findGroupsUsers();

						return {
							title: "Sucesso!",
							body: "Grupo de usuários salvo com sucesso.",
							config
						};
					} catch (error) {
						throw {
							title: "Falha!",
							body: "Erro ao tentar salvar o grupo de usuários.",
							config
						};
					}
				});
			},

			fecharModal (modal) {
				this.$refs[modal].hide();
			},

			async apagar (id) {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						await this.groupsUsersService.deleteGroupUser({id});
						this.findGroupsUsers();

						return {
							title: "Sucesso!",
							body: "Grupo de usuários apagado com sucesso.",
							config
						};
					} catch (error) {
						const msgError = {
							title: "Falha!",
							body: "Erro ao tentar apagar o grupo de usuários.",
							config
						};

						if (error.response.status === 400) {
							config.timeout = 5000;
							msgError.body = "Não é possível deletar um grupo de usuários que tem usuários ativos.";
						}

						throw msgError;
					}
				});
			},

			limparModal () {
				this.grupoUsuarioSelecionado.nome = "";
				this.grupoUsuarioSelecionado.funcionalidades.forEach(f => f.habilitada = false);
			},

			changeFuncionalidade (idx) {
				const item = this.grupoUsuarioSelecionado.funcionalidades[idx];
				item.habilitada = !item.habilitada;
				this.invalidForm = !this.grupoUsuarioSelecionado.nome;
			},

			filtraFuncionalidades (v) {
				const stringLength = v.length;
				this.grupoUsuarioSelecionado.funcionalidades.forEach((guf) => {
					const string = guf.nome.substr(0, stringLength);
					if (stringLength > 0 && string.toLowerCase() !== v.toLowerCase())
						guf.visible = false;
					else
						guf.visible = true;

					return;
				});
			},

			validateState (ref) {
				if (
					this.veeFields[ref]
					&& (this.veeFields[ref].dirty || this.veeFields[ref].validated)
				) {
					this.invalidForm = Boolean(this.veeErrors.items.length);
					return !this.veeErrors.has(ref);
				}

				return null;
			}
		}
	};
</script>

<style scoped>
	.table-funcionalidades {
		max-height: 40vh;
		overflow-y: scroll;
	}

	.text-funcionalidade {
		padding-left: 0px;
		padding-right: 0px;
		font-size: 1rem;
	}

	.label-funcionalidade {
		color: #000000 !important;
	}

	.table-th-funcionalidade {
		font-size: 1rem;
	}

	.table-td-funcionalidade {
		font-size: 16px;
	}

	.table-td-funcionalidade td {
		vertical-align: middle;
	}

	label {
		width: 160px;
	}

	.container-head {
		display: flex;
		height: 5rem;
		width: 100%;
	}

	.container-pages {
		display: flex;
		width: 70%;
	}

	.container-add-new {
		display: flex;
		width: 30%;
		height: 3rem;
		justify-content: right;
	}

	.add-button {
		width: 135;
		height: 43;
		background-color: #218838;
		color: #FFFFFF;
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		padding: .75rem 1.5rem;
		border-style: none;
		margin-bottom: .5rem;
		font-weight: 700;
	}

	.containerCard {
		max-width: 90.5%;
	}

	.head-row {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
		margin-top: -16px;
	}

	.text-head-row {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1rem;
		color: #000000;
		white-space: nowrap;
	}

	.modal-edicao-label {
		font-size: 23px;
	}

	.modal-edicao-input {
		font-size: 1rem;
	}

	.btn-modal {
		font-size: 1rem;
		font-weight: 400;
		color: white;
	}

	.btn-cancel {
		background-color: #343A40;
		border-radius: 4px 0 0 4px;
		border-style: none;
	}

	.btn-limpar {
		background-color: #6C757D;
		border-radius: 0 4px 4px 0;
		border-style: none;
	}

	.btn-salvar {
		background-color: #28A745;
		width: 168px;
		border-radius: 4px;
		border-style: none;
	}

	.table-body-detalhe {
		padding: 5px 0;
	}

	.container-add-new-100 {
		width: 100% !important;
	}
</style>
