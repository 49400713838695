import { HttpInterceptorsConfig } from "./httpInterceptors";
export class GroupsUsersService extends HttpInterceptorsConfig {
	async listGroupsUsers (filters) {
		const response = await this.axios.get("groupsUsers/listGroupsUsers", { params: { filters } });
		return response.data;
	}

	async upsertGroupUser (payload) {
		const response = await this.axios.post("groupsUsers/upsertGroupUser", payload);
		return response.data;
	}

	async deleteGroupUser (id) {
		const response = await this.axios.delete("groupsUsers/deleteGroupUser", { params: id });
		return response.data;
	}
}
